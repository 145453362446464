<template>
    <div>
        <el-form :model="urlInfo" ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-form-item label="链接：" prop="page" class="pageSelect" require>
                <el-input placeholder="推广链接" type="text" v-model="urlInfo.web_url" @change="setMsg"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        props:['urlMsg'],
        data () {
            return {
                urlInfo:{...this.urlMsg}
            }
        },
        components:{
        },
        methods: {
            setMsg(){
                this.$emit('getUrlMsg',this.urlInfo)
            },
        },
        watch: {
            urlMsg:{
                handler(){
                    this.urlInfo = {...this.urlMsg}
                },
                deep:true
            },
        }
    }
</script>
<style lang="scss">
    
</style>