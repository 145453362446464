<template>
  <div>
    <el-dialog
      :title="title"
      :visible="ageMixShow"
      width="800px"
      @close="close"
      @open="openInit"
      append-to-body
    >
      <div class="localsList mix-wrap-age">
        <p class="batch-p">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-select
            v-model="comValObj.age_min"
            placeholder="请选择"
            filterable
            clearable
            :disabled="!checkedDatas.length"
            @change="(val) => batchChange('age_min', val)"
          >
            <el-option
              :label="age"
              :value="age"
              v-for="age in ages"
              :key="age"
              :disabled="age > comValObj.age_max"
            ></el-option>
          </el-select>
          <span class="line">-</span>
          <el-select
            v-model="comValObj.age_max"
            placeholder="请选择"
            filterable
            clearable
            :disabled="!checkedDatas.length"
            @change="(val) => batchChange('age_max', val)"
          >
            <el-option
              :label="age ==ages[ages.length - 1] ? age + '+' : age"
              :value="age"
              v-for="age in ages"
              :key="age"
              :disabled="age < comValObj.age_min"
            ></el-option>
          </el-select>
        </p>
        <el-checkbox-group
          v-model="checkedDatas"
          @change="
            (val) => handleCheckedDatasChange(['age_min','age_max'], ageMsgList, val)
          "
        >
          <el-checkbox v-for="item in dataList" :label="item.id" :key="item.id">
            <el-tooltip v-if="item.name" :content="item.name">
              <span class="mixName">{{ item.name }}</span></el-tooltip
            >
            <div class="age-area">
              <el-select
                v-model="item.age_min"
                placeholder="请选择"
                filterable
                clearable
                style="width: 100%"
              >
                <el-option
                  :label="age"
                  :value="age"
                  v-for="age in ageListAll[item.id]"
                  :key="age"
                  :disabled="age > item.age_max"
                ></el-option>
              </el-select>
              <span class="line">-</span>
              <el-select
                v-model="item.age_max"
                placeholder="请选择"
                filterable
                clearable
                style="width: 100%"
              >
                <el-option
                  :label="age == ageListAll[item.id][ageListAll[item.id].length - 1] ? age + '+' : age"
                  :value="age"
                  v-for="age in ageListAll[item.id]"
                  :key="age"
                  :disabled="age < item.age_min"
                ></el-option>
              </el-select>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import mixBatchOperate from "@/views/adManagement/assets/js/mixBatchOperate";
export default {
  mixins: [mixBatchOperate],
  props: {
    ageMixShow: {
      type: Boolean,
      default: () => false,
    },
    ageMsgList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => "年龄",
    },
    'ageLocation':{
        type:Array,
        defalue:[]
    }
  },
  data() {
    return {
      initData: this.$store.getters.initData,
      list: [],
      dataList: JSON.parse(JSON.stringify(this.ageMsgList)),
      minList:[],
      // ages:[]
    };
  },
  computed: {
    ageListAll(){
      let obj = {};
      this.ageLocation.map(v=>{
        let ageMinList = []
        if(v.geo_locations && v.geo_locations.length){
            v.geo_locations.map(v=>{
                if(this.initData.targeting.TargetingAgeRange.specialAgeRange[v]){
                    ageMinList.push(this.initData.targeting.TargetingAgeRange.specialAgeRange[v])
                }
            })
        }
        let min = ageMinList.length?Math.max(...ageMinList):this.initData.targeting.TargetingAgeRange.min;
        let max = this.initData.targeting.TargetingAgeRange.max;
        this.minList.push(min);
        // obj.list = this.ageList(min.max);
        obj[v.id] = this.ageList(min,max);
      })
      return obj;
    },
    ages(){
      var list = [];
      let locations = this.ageLocation.map(v=>{
        if(this.checkedDatas.includes(v.id)){
          return v.geo_locations
        }
      }).flat();
      let ageMinList = []
      if(locations && locations.length){
          locations.map(v=>{
              if(this.initData.targeting.TargetingAgeRange.specialAgeRange[v]){
                  ageMinList.push(this.initData.targeting.TargetingAgeRange.specialAgeRange[v])
              }
          })
      }
      let min = ageMinList.length?Math.max(...ageMinList):this.initData.targeting.TargetingAgeRange.min;
      // this.$set(this.ageInfo,'age_min',minDefault)
      for (var i = min; i <= this.initData.targeting.TargetingAgeRange.max; i++) {
          list.push(i);
      }
      
      return list
  }
  },
  methods: {
    close() {
      this.backToInit(this.ageMsgList);
      this.$emit("update:ageMixShow", false);
    },
    openInit() {
      this.dataList = JSON.parse(JSON.stringify(this.ageMsgList));
    },
    sure() {
      this.$emit("setMixMsg", "age", this.dataList);
      // this.$emit("update:ageMixShow", false);
      this.close();
    },
    ageList(min,max) {
      var list = [];
      for ( var i = min; i <= max; i++ ) {
        list.push(i);
      }
      return list;
    },
    // ageListMutile(){
    //   console.log(Math.max(...this.minList));
    //   console.log(Math.max(...this.minList),this.initData.targeting.TargetingAgeRange.max);
    //   return this.ageList(Math.max(...this.minList),this.initData.targeting.TargetingAgeRange.max)
    // }
  },
  watch: {
    ageMsgList: {
      handler() {
        
      },
    },
  },
};
</script>
<style lang="scss" scope>
.localsList {
  .el-row {
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss">
.mix-wrap-age {
  .el-select {
    width: 100px !important;
  }
  .batch-p {
    display: flex;
    align-items: center;
    .line,
    .el-select {
      margin-left: 10px;
    }
  }
  .el-checkbox-group {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .el-checkbox {
      width: 95%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .el-checkbox__label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .age-area {
      display: flex;
      align-items: center;
    }
    .line {
      margin: 0 10px;
    }
    .mixName {
      width: 300px;
    }
  }
}
</style>