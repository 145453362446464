<template>
  <div>
    <el-form
      :model="adSetInfo"
      ref="ruleForm"
      label-width="140px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="投放优化目标："
        v-if="!editAdGroup"
      >
        <el-select
          v-model="adSetInfo.optimization_goal"
          placeholder="请选择"
          @change="setTarget"
          v-if="initData.ad"
          :disabled="goalEditFlag"
        >
          <el-option
            :label="item.desc"
            :value="item.key"
            v-for="(item, index) in optimizationGoals"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="投放策略："
        v-if="!editAdGroup"
      >
        <span class="celue">
          <span>{{
            adSetInfo.bid_strategy == 'LOWEST_COST_WITHOUT_CAP'
              ? '自动竞价'
              : adSetInfo.bid_strategy == 'COST_CAP'
              ? '目标费用'
              : adSetInfo.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS'
              ? '最低ROAS竞价'
              : '手动出价'
          }}</span>
          <el-tooltip
            class="item"
            effect="light"
            content="使用出价上限竞价策略，Facebook 会尽可能获得最多展示次数，且在每次竞拍中，竞价金额不会超过$2，按展示次数计费"
            placement="top-start"
          >
            <i
              class="el-icon-warning-outline"
              style="margin: 0 10px"
            ></i>
          </el-tooltip>
        </span>
        <el-button
          type="text"
          @click="setStrategyShow = true"
          v-if="!setStrategyShow"
          >调整</el-button
        >
        <el-button
          type="text"
          @click="setStrategyShow = false"
          v-if="setStrategyShow"
          >收起</el-button
        >
      </el-form-item>
      <div v-if="setStrategyShow || editAdGroup">
        <el-form-item
          :label="`${adSetInfo.is_cbo ? '广告系列' : '广告组'}竞价策略：`"
          prop="resource"
        >
          <el-radio-group
            v-model="adSetInfo.bid_strategy"
            :disabled="adSetInfo.is_cbo"
            @change="setBidStrage"
          >
            <el-radio
              :label="item.key"
              v-for="(item, i) in bidStrategyList"
              :key="i"
              >{{ item.desc }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="费用控制额："
          prop="bid_amount"
          v-show="adSetInfo.bid_strategy == 'COST_CAP' || adSetInfo.bid_strategy == 'LOWEST_COST_WITH_BID_CAP'"
          :rules="[
            { required: true, message: '出价金额不能为空', trigger: ['blur', 'change'] },
            { type: 'number', min: 0.01, message: '出价最小值为0.01' },
          ]"
        >
          <el-input
            v-model="adSetInfo.bid_amount"
            style="width：200px"
            @change="setBid"
            type="number"
          >
            <el-button slot="append">{{ currencyName }}</el-button>
          </el-input>
          <span style="margin-left: 15px">单次转化量费用</span>
        </el-form-item>
        <el-form-item
          label="保底roas："
          prop="roas_average_floor"
          v-show="adSetInfo.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS'"
          :rules="[
            { required: true, message: '最低roas金额不能为空', trigger: ['blur', 'change'] },
            { type: 'number', min: 0.01, message: '出价最小值为0.01' },
            { type: 'number', max: 200, message: '出价最大值为200' },
          ]"
        >
          <el-input
            v-model="adSetInfo.roas_average_floor"
            style="width: 200px"
            @change="setRoas"
          >
            <!-- <el-button slot="append">{{currencyName}}</el-button> -->
          </el-input>
          <!-- <span style="margin-left:15px">单次转化量费用</span> -->
        </el-form-item>
        <el-form-item
          label="转化时间窗："
          prop="region"
          v-if="adSetInfo.objective == 'OUTCOME_SALES'"
        >
          <el-select
            v-model="adSetInfo.attribution_spec"
            :disabled="!this.copy"
            @change="emitMsg"
          >
            <el-option
              :label="times.desc"
              :value="times.key"
              v-for="times in attributionSpec"
              :key="times.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="计费方式："
          prop="resource"
        >
          <el-radio-group
            v-model="adSetInfo.billing_event"
            :disabled="adSetInfo.is_cbo"
            @change="emitMsg"
          >
            <el-radio
              :label="types.key"
              v-for="types in targetTypeList"
              :key="types.desc"
              >{{ types.desc }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
export default {
  props: {
    bidAmountMsg: {
      type: Object,
      default: () => ({}),
    },
    showPayor: {
      type: Boolean,
      default: false,
    },
    // 是否是单个编辑广告组
    editAdGroup: {
      type: Boolean,
      default: false,
    },
    copy: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => {},
    },
    level: {
      type: String,
      default: 'adset',
    },
  },
  data() {
    return {
      targetTypeList: [],
      setStrategyShow: false,
      adSetInfo: JSON.parse(JSON.stringify(this.bidAmountMsg)),
    };
  },
  computed: {
    ...mapState('initData', ['initData']),
    ...mapGetters('initData', ['getOptimizationGoals', 'getBidStrategyList', 'getAttributionSpec']),
    // 复制参数
    paramsForm() {
      return this.params || {};
    },
    currencyName() {
      try {
        return this.$store.getters.currentUiUnit.name;
      } catch (e) {
        console.warn(e);
      }
    },
    timezoneName() {
      // console.log(this.$store.getters);
      return this.$store.getters.currentAccountInfo.timezoneName;
    },
    optimizationGoals() {
      return this.getOptimizationGoals(this.adSetInfo.objective);
    },
    bidStrategyList() {
      return this.getBidStrategyList({
        is_cbo: this.adSetInfo.is_cbo,
        objective: this.adSetInfo.objective,
        optimization_goal: this.adSetInfo.optimization_goal,
        smart_promotion_type: this.adSetInfo.smart_promotion_type,
      });
    },
    // 转化时间窗
    attributionSpec() {
      return this.getAttributionSpec(this.adSetInfo.optimization_goal);
    },
    // 优化目标是否可编辑
    goalEditFlag() {
      return (
        !this.copy ||
        (this.copy &&
          !this.paramsForm.is_create_target &&
          this.paramsForm.target_obj_level !== 'ACCOUNT' &&
          this.adSetInfo.is_cbo) ||
        (this.level == 'adset' && this.adSetInfo.is_cbo)
      );
    },
  },
  watch: {
    bidAmountMsg: {
      handler() {
        this.adSetInfo = JSON.parse(JSON.stringify(this.bidAmountMsg));
        this.adSetInfo.bid_amount = this.adSetInfo.bid_amount
          ? this.$store.getters.toFormatedMoney(this.adSetInfo.bid_amount).value
          : 0;
        let list = this.initData.ad.AdSetOptimizationGoal.filter((k) => k.key == this.bidAmountMsg.optimization_goal);
        this.targetTypeList = list[0].billingEvents;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 选择优化目标
    setTarget(v) {
      let list = this.initData.ad.AdSetOptimizationGoal.filter((k) => k.key == v);
      this.targetTypeList = list[0]?.billingEvents || [];
      this.adSetInfo.attribution_spec = 'click_1';
      this.adSetInfo.bid_strategy = 'LOWEST_COST_WITHOUT_CAP';
      this.emitMsg();
      // console.log(this.targetTypeList);
    },
    setBidStrage(v) {
      if (v == 'LOWEST_COST_WITHOUT_CAP') {
        this.adSetInfo.bid_amount = null;
        this.adSetInfo.roas_average_floor = null;
      }
      this.emitMsg();
    },
    setAmountMsg() {
      this.setStrategyShow = true;
      this.setTarget(this.bidAmountMsg.optimization_goal);
    },
    emitMsg() {
      let msg = JSON.parse(JSON.stringify(this.adSetInfo));
      msg.bid_amount = this.$store.getters.toBaseMoney(msg.bid_amount);
      msg.roas_average_floor = msg.roas_average_floor ? msg.roas_average_floor / 1 : null;
      this.$emit('getBidAmountMsg', { ...msg });
    },
    setBid(v) {
      this.$emit('setBid', v);
      this.emitMsg();
    },
    setRoas(v) {
      this.$emit('setRoas', v);
      this.emitMsg();
    },
  },
};
</script>
<style lang="scss" scoped></style>
