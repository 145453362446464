import { render, staticRenderFns } from "./budgetMix.vue?vue&type=template&id=2ba1ad91"
import script from "./budgetMix.vue?vue&type=script&lang=js"
export * from "./budgetMix.vue?vue&type=script&lang=js"
import style0 from "./budgetMix.vue?vue&type=style&index=0&id=2ba1ad91&prod&lang=scss&scope=true"
import style1 from "./budgetMix.vue?vue&type=style&index=1&id=2ba1ad91&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports