<template>
  <div>
    <el-dialog
      :title="title"
      :visible="budgetMixShow"
      width="800px"
      @close="close"
      @open="openInit"
      append-to-body
    >
      <div class="localsList mix-wrap-age">
        <p class="batch-p">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-input
            placeholder="请输入预算"
            style="margin-left:10px;width:200px"
            v-model="budget"
            @change="setAllBudget"
          >
            <div slot="append">{{ currencyName }}</div>
          </el-input>
        </p>
        <el-checkbox-group v-model="checkedDatas" @change="selectItem">
          <el-checkbox
            v-for="item in dataList"
            :label="item.id"
            :key="item.id"
            :disabled="
              !(
                (level == 'adset' && !item.is_cbo) ||
                (level == 'campaign' && item.is_cbo)
              )
            "
          >
            <el-tooltip v-if="item.name" :content="item.name">
              <span class="mixName">{{ item.name }}</span></el-tooltip
            >
            <div class="age-area">
              <el-input
                v-model="item.budget"
                @change="getBudgetMsg"
                v-if="
                  (level == 'adset' && !item.is_cbo) ||
                    (level == 'campaign' && item.is_cbo)
                "
              >
                <el-select
                  v-model="item.budget_type"
                  slot="prepend"
                  placeholder="请选择"
                  disabled
                >
                  <el-option label="单日预算" value="daily"></el-option>
                  <el-option label="总预算" value="lifetime"></el-option>
                </el-select>
                <el-button slot="append">{{ currencyName }}</el-button>
              </el-input>
              <span v-else style="margin:5px 0;"
                >当前是{{ item.is_cbo ? "广告系列" : "广告组" }}预算，请去{{
                  item.is_cbo ? "广告系列" : "广告组"
                }}修改</span
              >
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import mixBatchOperate from "@/views/adManagement/assets/js/mixBatchOperate";
export default {
  //   mixins: [mixBatchOperate],
  props: {
    budgetMixShow: {
      type: Boolean,
      default: () => false,
    },
    budgetMsgList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => "年龄",
    },
    level: {
      type: String,
      default: () => "'adset'",
    },
  },
  data() {
    return {
      initData: this.$store.getters.initData,
      list: [],
      dataList: [],
      checkedDatas: [],
      isIndeterminate: false,
      checkAll: false,
      budget: null,
    };
  },
  computed: {
    currencyName() {
      return this.$store.getters.currentUiUnit.name;
    },
  },
  methods: {
    close() {
      //   this.backToInit(this.budgetMsgList);
      this.dataList = JSON.parse(JSON.stringify(this.budgetMsgList));
      this.checkAll = false;
      this.isIndeterminate = false;
      this.checkedDatas = [];
      this.budget = null;
      this.$emit("update:budgetMixShow", false);
    },
    openInit() {
      this.dataList = JSON.parse(JSON.stringify(this.budgetMsgList));
      this.dataList.map((v) => {
        console.log(v);
        this.$set(v, "budget_type", v.daily_budget ? "daily" : "lifetime");
        this.$set(
          v,
          "budget",
          this.$store.getters.toFormatedMoney(
            v.daily_budget || v.lifetime_budget
          ).value
        );
      });
    },
    getBudgetMsg() {},
    selectItem(v) {
        console.log(v);
      this.isIndeterminate = v.length > 0 && v.length < this.dataList.length;
      if(v.length == 0){
          this.checkAll = false;
      }
      if(v.length == this.dataList.length){
          this.checkAll = true
      }
    },
    setAllBudget(val) {
      this.checkedDatas.map((v) => {
        this.dataList.map((item) => {
          if (item.id == v) {
            console.log(item);
            item.daily_budget = item.daily_budget
              ? (item.daily_budget = val)
              : item.lifetime_budget
              ? (item.lifetime_budget = val)
              : null;
            item.budget = item.daily_budget
              ? (item.daily_budget = val)
              : item.lifetime_budget
              ? (item.lifetime_budget = val)
              : null;
          }
        });
      });
    },
    handleCheckAllChange(val) {
      this.checkedDatas = [];
      if (val) {
        this.dataList.map((item) => {
          if (
            (!item.is_cbo && this.level == "adset") ||
            (item.is_cbo && this.level == "campaign")
          ) {
            this.checkedDatas.push(item.id);
          }
        });
      }
    },
    sure() {
        let data = JSON.parse(JSON.stringify(this.dataList));
        data.map(item=>{
            item[`${item.budget_type}_budget`] = this.$store.getters.toBaseMoney(item.budget);
            delete item.budget;
            delete item.budget_type;
        })
      this.$emit("setMixMsg", "budget", data);
      this.close();
    },
  },
  watch: {
    ageMsgList: {
      handler() {},
    },
  },
};
</script>
<style lang="scss" scope>
.localsList {
  .el-row {
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss">
.mix-wrap-age {
  .el-select {
    width: 100px !important;
  }
  .batch-p {
    display: flex;
    align-items: center;
    .line,
    .el-select {
      margin-left: 10px;
    }
  }
  .el-checkbox-group {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .el-checkbox {
      width: 95%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .el-checkbox__label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .age-area {
      display: flex;
      align-items: center;
    }
    .line {
      margin: 0 10px;
    }
    .mixName {
      width: 300px;
    }
  }
}
</style>
