<template>
    <div>
        <el-form v-model="ageInfo"  :label-width="labelWidth">
            <el-form-item label="年龄：">
                <el-col :span="5">
                    <el-form-item prop="date1">
                        <el-select v-model="ageInfo.age_min" placeholder="请选择" filterable
                                    clearable style="width:100%" @change="setAge">
                            <el-option :label="age" :value="age" v-for="age in ageList"
                                        :key="age" :disabled="age>ageInfo.age_max"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col class="line" :span="1" style="padding:0 20px;text-align:center;">-</el-col>
                <el-col :span="5">
                    <el-form-item prop="date2">
                        <el-select v-model="ageInfo.age_max" placeholder="请选择" filterable
                                    clearable style="width:100%" @change="setAge">
                            <el-option :label="age == ageList[ageList.length-1]?age+'+':age" :value="age" v-for="age in ageList"
                                        :key="age" :disabled="age<ageInfo.age_min"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        props:{
            'label-width':{
                type:String,
                default:'140px'
            },
            'ageMsg':{
                type:Object,
                default:(()=>{})
            },
            'locationList':{
                type:Array,
                defalue:[]
            }
        },
        data() {
            return {
                ageInfo:{age_min:this.ageMsg.age_min,age_max:this.ageMsg.age_max},
                initData:this.$store.getters.initData,
                minDefault:this.$store.getters.initData.targeting.TargetingAgeRange.minDefault
            }
        },
        computed:{
            ageList(){
                var list = [];
                let locations = this.locationList.map(v=>v.geo_locations).flat();
                let ageMinList = []
                if(locations && locations.length){
                    locations.map(v=>{
                        if(this.initData.targeting.TargetingAgeRange.specialAgeRange[v]){
                            ageMinList.push(this.initData.targeting.TargetingAgeRange.specialAgeRange[v])
                        }
                    })
                }
                let min = ageMinList.length?Math.max(...ageMinList):this.initData.targeting.TargetingAgeRange.min;
                let minDefault = ageMinList.length?Math.max(...ageMinList):this.initData.targeting.TargetingAgeRange.minDefault;
                this.minDefault = minDefault;
                // this.$set(this.ageInfo,'age_min',minDefault)
                for (var i = min; i <= this.initData.targeting.TargetingAgeRange.max; i++) {
                    list.push(i);
                }
                
                return list
            }
        },
        methods: {
            setAge(){
                this.$emit('getAge',this.ageInfo)
            }
        },
        watch: {
            ageMsg:{
                handler(){
                    if(this.ageInfo.age_min == this.ageMsg.age_min && this.ageInfo.age_max == this.ageMsg.age_max) return;
                    this.ageInfo = {age_min:this.ageMsg.age_min,age_max:this.ageMsg.age_max};
                },
                deep:true
            },
            minDefault(){
                if(this.ageInfo.age_min<this.minDefault){
                    this.$set(this.ageInfo,'age_min',this.minDefault);
                }
                this.setAge();
            }
        },
    }
</script>
<style lang="scss" scope>
    
</style>